.section2-main {
    background-color: rgb(0, 0, 0);
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    height: fit-content;
    flex-wrap: wrap;
    width: 100vw;
    padding: 32px 0;
}

.section2-div-cards {
    margin-top: 16px;
    display: flex;
    width: 82%;
    justify-content: space-evenly;
    flex-wrap: wrap;
}

.section2-card {
    background: linear-gradient(135deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0));
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    border-radius: 20px;
    border: 1px solid rgba(255, 255, 255, 0.18);
    box-shadow: 0 8px 32px 0 rgba(0, 0, 0, 0.37);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 340px;
    min-height: 200px;
    margin: 16px;
    cursor: pointer;
    padding: 24px;
    text-align: center;
}

.section2-card p {
    font-size: 18px;
}


@media (min-width: 1000px) {
    .section2-card:hover {
        width: 360px;
        transition: width 0.3s ease;
    }
}