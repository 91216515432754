.section1-main {
    background-color: rgb(6, 6, 6);
    color: white;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    height: fit-content;
    flex-wrap: wrap;
    width: 100vw;
}

.section1-main img {
    height: 360px;
    border-radius: 16px;
    margin: 16px;
}

.section1-div-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

.section1-section {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    max-width: 1200px;
    flex-wrap: wrap;
}

.section1-section h1 {
    font-size: 32px;
}

.section1-section p {
    display: flex;
    justify-content: center;
    width: 260px;
    align-items: center;
    font-size: 18px;
    margin: 20px 0px 8px 8px;
    border: solid 1px white;
    border-radius: 40px;
    padding: 12px;
}

.section1-section p:hover {
    transition: background-color 1s ease;
    background-color: white;
    color: black;
}

.section1-div {
    margin: 0 16px;
    text-align: center;
    display: flex;
    flex-direction: column;
    cursor: pointer;
    width: fit-content;
}