.floating-button {
    height: 72px;
    width: 72px;
    border: none;
    border-radius: 80px;
    background-color: rgba(253, 0, 0, 0);
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    bottom: 0;
    z-index: 1000;
    margin: 16px;
    left: 0;
    cursor: pointer;
}

.floating-button img {
    aspect-ratio: 1;
    width: 104px;
}

.floating-button img:hover {
    width: 106px;
}