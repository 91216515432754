.footer-main {
    background-color: rgb(0, 0, 0);
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-wrap: wrap;
    min-height: 200px;
}

.footer-right {
    display: flex;
    color: white;
    justify-content: center;
    align-items: center;
    margin: 8px;
    width: 328px;
    gap: 8px;
}

.footer-right h3 {
    width: fit-content;
    display: flex;
    text-align: start;
    justify-content: center;
    align-items: center;
    font-size: 15px;
    color: white
}

.footer-nav {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    max-width: 200px;
    color: rgb(255, 255, 255);
    margin: 16px 0;
}

/* Centering the title and links on the same row */
.footer-title {
    text-align: center;
    font-size: 15px;
    font-weight: bold;
    color: rgb(255, 255, 255);
    /* Space between title and links */
    cursor: pointer;
    width: 224px;
}

/* Navigation links */
.footer-links {
    display: flex;
    list-style: none;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 16px;
    /* Spacing between links */
}

.footer-links a {
    text-decoration: none;
    color: rgb(255, 255, 255);
    display: flex;
    position: relative;
    font-size: 15px;
    padding: 8px;
    transition: color 0.3s ease;
    justify-content: center;
}

.footer-links a:hover {
    color: #404040;
}

/* Underline animation */
.footer-links a::after {
    content: '';
    position: absolute;
    width: 0;
    height: 2px;
    left: 0;
    bottom: 0;
    background-color: rgb(255, 255, 255);
    transition: width 0.3s ease;
}

.footer-links a:hover::after {
    width: 100%;
}

@media (max-width: 1400px) {
    .footer-links {
        max-width: 300px;
        flex-wrap: wrap;
    }
  }