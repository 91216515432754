.termeni-main {
    display: flex;
    flex-direction: column;
    margin-top: 80px;
    background-color: rgb(2, 2, 2);
    color: white;
    gap: 16px;
    padding: 80px;
}

.termeni-button {
    border: 2px white solid;
    border-radius: 4px;
    background-color: black;
    width: 200px;
    color: white;
    padding: 8px;
    cursor: pointer;
}

.termeni-button:hover {
    background-color: white;
    color: black;
}