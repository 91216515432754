.galerie-main {
    background-color: rgb(2, 2, 2);
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-wrap: wrap;
    width: 100vw;
    gap: 40px;
    margin-top: 80px;
}

.galerie-main h2 {
    font-size: 28px;
    text-align: center;
    color: white;
    margin-bottom: 24px;
    margin-top: 80px;
}

.galerie-main p {
    text-align: center;
    color: white;
    font-size: 18px;
    margin-bottom: 16px;
}

.galerie-images-locatii, .galerie-images-modele {
    gap: 24px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin-bottom: 32px;
}

.galerie-images-locatii img {
    margin: 0px;
    opacity: 0.8;
    height: 420px;
    border-radius: 16px;
    padding: 0px;
}
.galerie-images-modele img {
    margin: 0px;
    opacity: 0.8;
    height: 420px;
    border-radius: 16px;
}

@media (max-width: 480px) {
    .galerie-images-modele img {
        max-height: 400px;
    }
    .galerie-images-locatii img {
        max-height: 192px;
    }
}