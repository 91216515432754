.hero-main {
    background-color: rgb(2, 2, 2);
    display: flex;
    justify-content: center;
    gap: 80px;
    align-items: center;
    flex-direction: row;
    width: 100%;
    height: 95vh;
    flex-wrap: wrap;
    margin-top: 80px;
}

.hero-left {
    max-width: 750px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.hero-left h2 {
    font-size: 28px;
    text-align: center;
    color: white;
    margin-bottom: 8px;
}

.hero-left p {
    text-align: center;
    color: white;
    font-size: 18px;
    margin-bottom: 16px;
}

.hero-images {
    display: flex;
    max-height: 385px;
    justify-content: center;
    align-items: center;
}

.hero-left img {
    margin: 8px;
    opacity: 0.8;
    height: 380px;
    border-radius: 16px;
}

.hero-left img:hover {
    height: 385px;
    transition: height 0.3s ease;
}

.hero-right {
    display: flex;
    justify-content: center;
    align-items: center;
}

.hero-right img {
    margin: 8px;
    opacity: 0.8;
    height: 640px;
    border-radius: 16px;
}

.hero-right img:hover {
    height: 645px;
    transition: height 0.3s ease;
}

.hero-left button {
    text-decoration: none;
    background-color: black;
    border: solid 1px;
    border-radius: 40px;
    padding: 8px;
    width: 200px;
    align-self: center;
    color: white;
    font-family: 'Syne';
    font-size: 15px;
    margin-bottom: 40px;
}

.hero-left button:hover {
    background-color: white;
    transition: background-color 1s ease;
    color: black;
    cursor: pointer;
}

@media (max-width: 828px) {
    .hero-left {
        max-width: 300px;
    }

    .hero-main {
        height: 104vh;
    }

    .hero-images {
        max-width: 300px;
        overflow-y: hidden;
        overflow-x: auto;
        justify-content: flex-start;
    }
}

@media (max-width: 1350px) {
    .hero-right {
        display: none;
    }
}