@import url('https://fonts.googleapis.com/css2?family=Cormorant:ital,wght@0,300..700;1,300..700&family=Syne:wght@400..800&display=swap');

/* width */
::-webkit-scrollbar {
  width: 4px;
  height: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #121212;
  border-radius: 80px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #242424;
  border-radius: 80px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #262626;
}

* {
  margin: 0;
  padding: 0;
}

html {
  overflow-x: hidden; /* Always show a vertical scrollbar */
}

body {
  font-family: 'Syne';
  display: grid;
  overflow-x: hidden;
}