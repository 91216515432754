.section3-main {
    background-color: rgb(6, 6, 6);
    color: white;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    height: fit-content;
    flex-wrap: wrap;
    width: 100vw;
    min-height: 320px;
    padding: 32px 0;
}

.section3-main img {
    height: 400px;
    border-radius: 16px;
}

.section3-left {
    margin: 16px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.section3-left p {
    margin: 16px;
    text-align: center;
    font-size: 20px;
    max-width: 460px;
}

.section3-right {
    margin: 16px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 16px;
}

.section3-right button {
    text-decoration: none;
    background-color: black;
    border: solid 1px;
    border-radius: 40px;
    padding: 16px;
    width: 280px;
    align-self: center;
    color: white;
    font-family: 'Syne';
    font-size: 18px;
    margin-bottom: 40px;
}

.section3-right button:hover {
    background-color: white;
    transition: background-color 1s ease;
    color: black;
    cursor: pointer;
}

.section3-right h2 {
    font-size: 28px
}