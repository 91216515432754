.navbar {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80px;
  background-color: rgb(255, 255, 255);
  color: rgb(0, 0, 0);
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 1000;
  margin: 0;
  padding: 0;
  left: 0;
  gap: 16px;
}

.navbar-title {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 224px;
  font-size: 15px;
  font-weight: bold;
  color: rgb(0, 0, 0);
  cursor: pointer;
}

.navbar-links {
  list-style: none;
  display: flex;
  gap: 30px;
}

.navbar-links a {
  text-decoration: none;
  color: rgb(0, 0, 0);
  position: relative;
  font-size: 15px;
  padding: 8px;
  transition: color 0.3s ease;
}

.navbar-links a:hover {
  color: #404040;
}

.navbar-links a::after {
  content: '';
  position: absolute;
  width: 0;
  height: 2px;
  left: 0;
  bottom: 0;
  background-color: rgb(0, 0, 0);
  transition: width 0.3s ease;
}

.navbar-links a:hover::after {
  width: 100%;
}

.navbar-menu {
  display: flex;
  position: absolute;
  right: 24px;
}

.navbar-sidemenu {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  width: 100vw;
  height: calc(100vh - 80px);
  background-color: rgb(0, 0, 0);
  z-index: 2;
  margin-top: 80px;
}

.navbar-sidemenu-links li{
  list-style: none;
  gap: 30px;
  margin: 32px 0;
  text-align: center;
}

.navbar-sidemenu-links a {
  text-decoration: none;
  color: rgb(255, 255, 255);
  font-size: 15px;
  padding: 8px;
  transition: color 0.3s ease;
  font-size: 48px;
  text-align: center;
}

@media (max-width: 768px) {
  .navbar-links {
    display: none;
  }
}

@media (min-width: 769px) {
  .navbar-menu {
    display: none;
  }
}